<template>
  <v-card class="pa-0 ma-0">
    <v-card-title class="pb-0 pt-0">
      <v-row>
        <v-col cols="12" lg="2">
          <v-select :items="listOfFilters" item-text="title" item-value="id" v-model="filter" outlined dense hide-details></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="6" class="text-end">
          <v-btn color="success" class="mr-3" @click="redirectToList()">{{ $t('goToList') }}</v-btn>
          <v-btn color="primary" class="mr-3" @click="redirectToForm()" v-if="canCreateSale">{{ $t('createSale') }}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel dark v-for="(item,i) in boardStatus" :key="i" class="reducepadding">
        <v-expansion-panel-header expand-icon="mdi-menu-down" color="#9e9e9e1f">{{ $t(item.label) }}
          <v-spacer></v-spacer>
          <span class="text-end">
          <v-chip color="info"> {{item.count}}</v-chip>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0 ma-0">
          <b>
            <span id="sale-header" v-if="item.count" class="pa-2" style="text-transform: uppercase; !important">{{ $t('total') }} :
              {{ item.total }}
            </span>
          </b>
          <v-list class="pa-1 ma-1 pt-3 mb-0 text-style">
            <template v-for="item in item.sales">
              <v-card :key="item.id" class="mb-2 pa-2" @click="redirectToView(item.sale._id)">
                <v-row class="pa-0 ma-0">
                  <v-col cols="9" class="pa-0 ma-0">
                    <span style="color: #0091ae"> {{ item.sale.data.number ? `${item.sale.data.number} - ` : ''}} {{ item.sale.data.name }} </span>
                  </v-col>
                  <v-col cols="3" v-if="item.sale.data.price" class="pa-0 ma-0 text-right" style="font-size: 12px;">
                    {{ item.sale.data.price }}
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0" style="font-size: 12px;">
                    {{ $t('assignee') }} : {{ item.responsiblename }}
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0" style="font-size: 12px;">
                    {{ $t('customerName') }} : {{item.companyname }}
                  </v-col>
                  <v-col cols="12" v-if="item.sale.data.date" class="pa-0 ma-0" style="font-size: 12px;">
                    {{ $t('sale_deadline') }} : {{ (item.sale.data.deadline) ? $formatter.formatDate(item.sale.data.deadline, '', userDetails.dateformat) : '' }}
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      filter: 1,
      boardStatus: [],
      selectedDropItem: {},
      changedList: [],
      panel: [],
      listOfStages: [],
      canCreateSale: false
    }
  },
  computed: {
    ...mapGetters(['userDetails', 'listOfModules']),
    listOfFilters () {
      return [{
        id: 1,
        title: 'All Deals'
      }, {
        id: 2,
        title: 'My Deals'
      }, {
        id: 3,
        title: 'My won deals this month'
      }, {
        id: 4,
        title: 'New deals this month'
      }]
    }
  },
  watch: {
    '$store.state.common.listOfModules' (val) {
      this.checkPermission()
      return ''
    }
  },
  mounted () {
    this.getListOfSalesStage()
    this.checkPermission()
  },
  methods: {
    getListOfSalesStage () {
      const query = [{ $match: { Module_Id: this.$route.params.id } }]
      this.$api.execute('post', 'modulefields/query', query)
        .then((result) => {
          if (result && result.data) {
            this.listOfStages = result.data
            const stage = result.data.find(x => x.name === 'stage')
            let options = []
            if (stage) {
              const defaultValue = stage.default_value ? JSON.parse(stage.default_value) : {}
              options = defaultValue.options
            }
            options.forEach(element => {
              if (!element.sales) {
                element.sales = []
                element.total = 0
                element.count = 0
              }
            })
            this.boardStatus = options
          }
        }).finally(() => {
          this.getDashboardItems()
        })
    },
    getDashboardItems () {
      this.$api.execute('get', 'sales/get_dashboard').then(response => {
        const stages = response.data
        const newStatusList = []
        this.boardStatus.forEach((status) => {
          const result = stages.find(x => x.stage === status.value)
          if (result) {
            newStatusList.push({ ...status, ...result })
          } else newStatusList.push({ ...status })
        })
        this.boardStatus = newStatusList
      })
    },
    redirectToList () {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}`)
    },
    redirectToForm () {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}/actions`)
    },
    redirectToView (id) {
      this.$router.push(`/module/${this.$route.params.name}/${this.$route.params.id}/view/${id}`)
    },
    // onItemDropped (item) {
    //   const boardStatusList = this.$formatter.cloneVariable(this.boardStatus)
    //   if (item && this.selectedDropItem) {
    //     const newStage = item.value
    //     const result = boardStatusList.findIndex(x => x.stage === newStage || x.value === newStage)
    //     if (result !== -1) {
    //       if (!boardStatusList[result].sales) boardStatusList[result].sales = []
    //       let removeIndex = boardStatusList.findIndex(x => x.value === this.selectedDropItem.data.stage)
    //       let saleIndex = boardStatusList[removeIndex].sales.findIndex(x => x._id === this.selectedDropItem._id)
    //       this.selectedDropItem.data.stage = newStage
    //       boardStatusList[result].sales.push(this.selectedDropItem)
    //       boardStatusList[removeIndex].sales.splice(saleIndex, 1)
    //     }
    //     this.changedList = boardStatusList
    //     this.changedList.forEach(element => {
    //       element.count = element.sales && element.sales.length ? element.sales.length : 0
    //       let total = 0
    //       if (element.sales && element.sales.length > 0) {
    //         element.sales.forEach(sale => {
    //           total = total + (sale.data.price ? parseInt(sale.data.price) : 0)
    //         })
    //       }
    //       element.total = total
    //     })
    //   }
    // },
    updateBoardValues (event) {
      const model = this.$formatter.cloneVariable(this.selectedDropItem)
      this.boardStatus = this.$formatter.cloneVariable(this.changedList)
      if (this.selectedDropItem) {
        this.$api.execute('put', `/moduledata/${this.$route.params.name}/${model._id}`, model)
      }
      this.selectedDropItem = {}
      this.changedList = {}
    },
    selectedItem (item) {
      this.selectedDropItem = item
    },
    checkPermission () {
      if (this.listOfModules.length > 0) {
        const modulename = this.$route.params.name
        const value = this.listOfModules
        const isExists = value.find(x => x.name === modulename)
        if (isExists) {
          if (modulename === this.SALES) { // checking whether sales feature enabled for the tenant
            const hasAccess = this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0 && this.userDetails.allowedfeatures.includes(this.SALES_AND_MARKETING)
            if (hasAccess) this.permissionChecking()
            else this.$router.push('/notavailable')
          } else this.permissionChecking()
        } else this.$router.push('/dashboard')
      }
    },
    permissionChecking () {
      const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleId, this.userDetails)
      this.canCreateSale = hasManagePermission
      this.showPageLoader = false
    }
  }
}
</script>
<style>
#sale-header {
  text-transform: uppercase !important;
}
.border-class {
  border-bottom: 1px solid rgb(223, 227, 235);
}
.reducepadding .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}
.reducepadding .v-expansion-panel-header {
  display: flex !important;
}
</style>
